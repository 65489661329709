@import "./styles/tailwind.generated.css";
/* @import "@progress/kendo-theme-bootstrap/dist/all.css"; */
@import "material-icons/iconfont/material-icons.css";
@import "react-toastify/dist/ReactToastify.css";
@import "react-confirm-alert/src/react-confirm-alert.css";

.Toastify__toast-container {
  z-index: 50000;
}

.react-confirm-alert-body > h1 {
  font-weight: bold;
  margin-bottom: 0.4rem;
}
